import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Theme from "../lib/Theme"
import { pxToRem } from "../lib/utils"

interface BaseHeadingProps {
  size?: number
  uppercase?: boolean
  as?: string
  diap?: boolean
}

export const Heading = styled("h3", {
  shouldForwardProp: (prop: string) =>
    ["size", "uppercase", "diap", "as"].includes(prop) === false,
})<BaseHeadingProps>`
  margin: 0 0 1rem 0;
  line-height: 1.2;
  color: ${(p) => (p.diap ? "#fff" : Theme.Colors.purple.s100)};
  font-family: ${Theme.Font.heading.family};
  font-weight: ${Theme.Font.heading.bold};
  font-size: ${(p) => pxToRem(p.size || 24)};

  ${(p) =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${Theme.Mediaquery.medium.max} {
    font-size: ${(p) => pxToRem(p.size ? p.size * 0.8 : 20)};
  }
`

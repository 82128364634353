import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Theme from "../lib/Theme"

export const Button = styled.button<{ small?: boolean; large?: boolean }>`
  font-family: ${Theme.Font.body.family};
  font-weight: ${Theme.Font.body.bold};

  background: ${Theme.Colors.pink.s100};
  background-image: linear-gradient(
    ${Theme.Colors.pink.s100},
    ${Theme.Colors.pink.s125}
  );
  border-radius: 5px;
  padding: 0.8em 1.4em;
  border: none;
  display: inline-block;
  appearance: none;
  cursor: pointer;

  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 0.9rem;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline-color: ${Theme.Colors.pink.s100};
    background-image: linear-gradient(
      ${Theme.Colors.pink.s125},
      ${Theme.Colors.pink.s150}
    );
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background: ${Theme.Colors.pink.s150};
  }

  &:disabled {
    cursor: default;
    background: ${Theme.Colors.grey.s50};
  }

  ${(p) =>
    p.small &&
    css`
      font-size: 0.8rem;
      padding: 0.4em 1.4em;
    `}

  ${(p) =>
    p.large &&
    css`
      font-size: 1.2rem;
    `}
`

export const AnchorButton = Button.withComponent("a")
export const LinkButton = Button.withComponent(Link)

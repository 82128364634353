import { Cloudinary } from "cloudinary-core"
import hexRgb from "hex-rgb"

const cl = new Cloudinary({ cloud_name: "schriktraining-deluxe", secure: true })

export const rgba = (hex, alpha) => {
  const rgb = hexRgb(hex, { format: "array" }).slice(0, -1).join(",")
  return `rgba(${rgb},${alpha})`
}

export const pxToRem = (px: number) => `${(px / 16).toFixed(2)}rem`

export const isBrowser = typeof window !== "undefined"

export const browserOnly = (str: string, fallback?: string) => {
  if (isBrowser) {
    return str
  }

  return fallback || undefined
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export const getCloudinaryOgImageUrl = (url: string) => {
  return cl.url(url, { type: "fetch", width: 1200, height: 630, crop: "fill" })
}

interface Props {
  eventCategory: string
  eventAction: string
  eventName?: string
  eventValue?: number
}

export const trackCustomEvent = ({
  eventCategory,
  eventAction,
  eventName,
  eventValue,
}: Props) => {
  if (process.env.NODE_ENV === "production") {
    if (!(window as any)._paq) return

    const { _paq } = window as any

    _paq.push([`trackEvent`, eventCategory, eventAction, eventName, eventValue])
  }
}

import styled from "@emotion/styled"
import { FC } from "react"
import CloseIcon from "../images/icons/close.svg"
import Theme from "../lib/Theme"

export const Popup: FC<{ imageUrl: string; onClose?: () => void }> = ({
  children,
  imageUrl,
  onClose,
}) => (
  <Wrap>
    <Overlay
      onClick={() => {
        if (onClose) {
          onClose()
        }
      }}
    />
    <Content>
      <Img style={{ backgroundImage: `url(${imageUrl})` }} />
      <Inner>
        {onClose && (
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
        )}
        {children}
      </Inner>
    </Content>
  </Wrap>
)

const Wrap = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  background: #fff;
  display: flex;
  width: 50vw;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  ${Theme.Mediaquery.medium.max} {
    width: 95%;
    flex-direction: column-reverse;
  }
`

const Inner = styled.div`
  padding: 2rem;
  flex: 4;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Theme.Mediaquery.medium.min} {
    max-width: 75vw;
  }
`

const Img = styled.div`
  background: transparent center center no-repeat;
  background-size: cover;
  flex: 3;

  ${Theme.Mediaquery.medium.max} {
    display: none;
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;

  cursor: pointer;
  background: none;
  border: none;
  opacity: 0.5;

  &:focus {
    opacity: 1;
    fill: ${Theme.Colors.pink.s100};
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    fill: ${Theme.Colors.purple.s100};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`

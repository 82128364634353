import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import Theme from "../lib/Theme"
import { Heading } from "./Heading"
import { Text } from "./Text"

export interface HeadingBlockProps {
  title: string
  titleAs?: string
  subtitle?: string
  children?: JSX.Element
  diap?: boolean
}

export const HeadingBlock = ({
  title,
  children,
  subtitle,
  titleAs,
  diap,
}: HeadingBlockProps) => (
  <Wrap diap={diap}>
    {subtitle ? <Subline>{subtitle}</Subline> : <Subline as="span" />}

    <Heading size={32} as={titleAs || ("h3" as any)}>
      {title}
    </Heading>
    <Text as="div">{children}</Text>
  </Wrap>
)

const Wrap = styled.header<{ diap: boolean }>`
  margin-bottom: 2rem;

  ${(p) =>
    p.diap &&
    css`
      color: #fff;

      ${Subline} {
        color: #fff;
        opacity: 0.75;
      }
    `}

  ${Heading} {
    margin: 0 0 1rem 0;
  }

  ${Text} {
    font-size: 1.1rem;
    margin: 1rem 0;
    max-width: 40rem;

    a {
      color: ${Theme.Colors.pink.s100};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: ${Theme.Colors.purple.s100};
      }
    }
  }
`

const Subline = styled.p`
  color: ${Theme.Colors.purple.s50};
  margin: 0 0 0.5rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;

  &:after {
    display: inline-block;
    content: "";
    background-color: currentColor;
    height: 1px;
    position: relative;
    vertical-align: middle;
    max-width: 100%;
    width: 50%;
    left: 1em;
    opacity: 0.25;
  }

  span& {
    display: block;

    &::after {
      left: 0;
    }
  }
`

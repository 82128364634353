import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Theme from "../lib/Theme"

export const Row = styled.div<{ first?: boolean }>`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 75rem;

  ${(p) =>
    p.first &&
    css`
      margin-top: 4rem;
    `}

  ${Theme.Mediaquery.medium.min} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

export const ContentRow = styled(Row)`
  max-width: calc(100% - 2rem);
  margin: 2rem auto;
  padding: 0;

  ${Theme.Mediaquery.medium.min} {
    max-width: 60rem;
  }
`

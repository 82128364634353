import "typeface-lato"
import "typeface-montserrat"
import { pxToRem } from "./utils"

class Viewport {
  private minWidth: string
  private maxWidth: string

  constructor(widthInPixels: number) {
    this.minWidth = pxToRem(widthInPixels)
    this.maxWidth = pxToRem(widthInPixels - 1)
  }

  get min() {
    return `@media (min-width: ${this.minWidth})`
  }

  get max() {
    return `@media (max-width: ${this.maxWidth})`
  }
}

export default {
  Colors: {
    purple: {
      s200: "#000000",
      s150: "#060008",
      s125: "#2e003b",
      s100: "#56006e",
      s75: "#804092",
      s50: "#ab80b7",
      s25: "#d5bfdb",
      s10: "#e6d9e9",
    },
    pink: {
      s200: "#9c0b43",
      s150: "#cb0f57",
      s125: "#ef1e6e",
      s100: "#f24e8d",
      s75: "#f57aaa",
      s50: "#f9a7c6",
      s25: "#fcd3e3",
      s10: "#fde4ee",
    },
    grey: {
      s200: "#000000",
      s150: "#000000",
      s125: "#000000",
      s100: "#220c10",
      s75: "#59494c",
      s50: "#918688",
      s25: "#c8c2c3",
      s10: "#dedbdb",
    },
  },

  Font: {
    heading: {
      family: "'Montserrat', 'Comic Sans', sans-serif",
      normal: 400,
      bold: 700,
    },
    body: {
      family: "'Lato', 'Comic Sans', sans-serif",
      normal: 400,
      bold: 700,
    },
  },

  Mediaquery: {
    small: new Viewport(600),
    medium: new Viewport(768),
    large: new Viewport(980),
    huge: new Viewport(1024),
    gigantic: new Viewport(1680),
  },
}

import { Image, Placeholder, Transformation } from "cloudinary-react"
import React, { FC } from "react"

interface Props {
  publicId: string
  alt: string
  width?: number
  height?: number
  crop?: "scale" | "fill"
  gravity?: "faces" | "face"
  noPlaceholder?: boolean
  onClick?: () => void
  className?: string
}

export const CloudinaryImage: FC<Props> = ({
  width,
  height,
  crop,
  noPlaceholder,
  ...props
}) => (
  <Image type="fetch" loading="lazy" {...props}>
    <Transformation
      width={width}
      height={height}
      crop={crop}
      quality="auto"
      fetchFormat="auto"
    />
    {noPlaceholder === true ? null : <Placeholder type="blur" />}
  </Image>
)

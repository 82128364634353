import styled from "@emotion/styled"
import { FC } from "react"
import { Button } from "./Button"
import { VisuallyHidden } from "./VisuallyHidden"

export const MailchimpForm: FC<{ small?: boolean; onSubmit?: () => void }> = ({
  small,
  onSubmit,
}) => (
  <Wrap
    action="https://schriktrainingdeluxe.us1.list-manage.com/subscribe/post?u=71b4d399bd70c8e12d9f071fe&amp;id=4b149dcd5a"
    method="post"
    id="mc-embedded-subscribe-form"
    name="mc-embedded-subscribe-form"
    target="_blank"
    onSubmit={() => {
      setTimeout(() => {
        onSubmit()
      }, 100)
    }}
    noValidate
  >
    <VisuallyHidden aria-hidden="true">
      <label htmlFor="mce-EMAIL">Je e-mailadres</label>
    </VisuallyHidden>

    <Input
      placeholder="E-mailadres..."
      type="email"
      name="EMAIL"
      id="mce-EMAIL"
      required
    />

    <VisuallyHidden aria-hidden="true">
      <input
        type="text"
        name="b_71b4d399bd70c8e12d9f071fe_4b149dcd5a"
        tabIndex={-1}
        defaultValue=""
      />
    </VisuallyHidden>

    <Button small={small} type="submit" name="subscribe">
      Aanmelden
    </Button>
  </Wrap>
)

const Wrap = styled.form`
  position: relative;

  ${Button} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 5px 5px 0;
  }
`

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.75rem;
  padding-right: 8rem;
  border-radius: 5px;
  border: none;
  background: #fff;
`

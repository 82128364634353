import styled from "@emotion/styled"
import strokeBg from "../images/stroke.svg"
import Theme from "../lib/Theme"
import { pxToRem } from "../lib/utils"

interface BaseTextProps {
  size?: number
  as?: string
}

export const Text = styled("p", {
  shouldForwardProp: (prop: string) => ["size", "as"].includes(prop) === false,
})<BaseTextProps>`
  font-family: ${Theme.Font.body.family};
  font-weight: ${Theme.Font.body.normal};
  font-size: ${(p) => (p.size ? pxToRem(p.size) : "1rem")};

  strong {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0.5rem;
    margin: -0.5rem -0.4rem;
    color: #333;
    font-weight: ${Theme.Font.body.normal};
    background: url(${strokeBg}) center 55% no-repeat;
    background-size: contain;
  }
`

import styled from "@emotion/styled"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import FbIcon from "../images/icons/facebook.svg"
import IgIcon from "../images/icons/instagram.svg"
import logoPurpleSrc from "../images/logo-purple.svg"
import Theme from "../lib/Theme"
import { useScroll } from "../lib/useScroll"
import { Row } from "./Row"
import { SocialIcon } from "./SocialIcon"

interface Props {
  links: Array<{
    label: string
    url: string
  }>
}

export const Navigation = ({ links }: Props) => {
  const scroll = useScroll()
  const [showMobile, setShowMobile] = useState(false)
  const [compact, setCompact] = useState(false)

  useEffect(() => {
    if (scroll.direction === "up") {
      return setCompact(false)
    }

    setCompact(scroll.y > 200)
  }, [scroll.y, scroll.direction])

  return (
    <Wrap>
      <NavRow>
        <ToggleButton onClick={() => setShowMobile(!showMobile)}>
          Menu
        </ToggleButton>

        <NavLinks open={showMobile}>
          <Link
            className="link"
            to="/#pakketten"
            data-track-content
            data-content-name="CTA"
            data-content-piece="Pakket Btn Nav"
          >
            Bekijk pakketten
          </Link>

          {links.map((item) => (
            <Link className="link" key={item.url} to={`${item.url}/`}>
              {item.label}
            </Link>
          ))}

          <MobileOnly>
            <Link className="link" to="/samenwerkingen/">
              Samenwerkingen
            </Link>

            <Link className="link" to="/contact/">
              Contact
            </Link>

            <a
              className="link"
              href="https://www.facebook.com/schriktrainingdeluxe"
              target="_blank"
              rel="noreferrer nofollow noopener"
            >
              Facebook
            </a>
            <a
              className="link"
              href="https://www.instagram.com/schriktrainingdeluxe"
              target="_blank"
              rel="noreferrer nofollow noopener"
            >
              Instagram
            </a>
          </MobileOnly>
        </NavLinks>

        <LogoLink to="/" compact={compact}>
          <img src={logoPurpleSrc} alt="Schriktraining Deluxe" />
        </LogoLink>

        <CtaWrap>
          <Link className="link" to="/samenwerkingen/">
            Samenwerkingen
          </Link>

          <Link className="link" to="/contact/">
            Contact
          </Link>

          <SocialIcon
            href="https://www.facebook.com/schriktrainingdeluxe"
            target="_blank"
            rel="noreferrer nofollow noopener"
            title="Facebook"
            color={Theme.Colors.pink.s100}
          >
            <FbIcon />
            <FbIcon />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/schriktrainingdeluxe"
            target="_blank"
            rel="noreferrer nofollow noopener"
            title="Instagram"
            color={Theme.Colors.purple.s100}
          >
            <IgIcon />
            <IgIcon />
          </SocialIcon>
        </CtaWrap>
      </NavRow>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .link {
    text-decoration: none;
    text-transform: uppercase;
    color: ${Theme.Colors.purple.s100};

    &:hover {
      color: ${Theme.Colors.pink.s100};
    }
  }

  ${Theme.Mediaquery.medium.max} {
    .link {
      color: ${Theme.Colors.purple.s100};
      display: block;
      text-align: center;
      padding: 1rem 0;
    }
  }

  ${Theme.Mediaquery.medium.min} {
    .link {
      & + a {
        margin-left: 1rem;
      }
    }
  }

  ${Theme.Mediaquery.huge.min} {
    .link {
      & + a {
        margin-left: 2rem;
      }
    }
  }
`

const NavRow = styled(Row)`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  ${Theme.Mediaquery.medium.min} {
    grid-template-columns: 1fr auto 1fr;
    padding-left: 1em;
    padding-right: 1em;
  }

  ${Theme.Mediaquery.large.min} {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const LogoLink = styled(Link)<{ compact: boolean }>`
  img {
    display: inline-block;
    height: 2rem;
    position: relative;
    will-change: height;
    transition: height 0.3s 0.2s;
  }

  ${Theme.Mediaquery.medium.min} {
    text-align: center;
    top: -0.3rem;

    img {
      height: ${(p) => (p.compact ? "2em" : "3em")};
    }
  }
  ${Theme.Mediaquery.large.min} {
    top: -0.3rem;

    img {
      height: ${(p) => (p.compact ? "2em" : "4em")};
    }
  }
`

const ToggleButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  text-transform: uppercase;
  border: none;
  background: none;

  font-size: 0.8rem;
  padding: 0.4rem;

  &:focus {
    outline-color: ${Theme.Colors.pink.s100};
  }

  ${Theme.Mediaquery.medium.min} {
    display: none;
  }
`

const NavLinks = styled.nav<{ open: boolean }>`
  ${Theme.Mediaquery.medium.max} {
    display: ${(p) => (p.open ? "block" : "none")};

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

    .link {
      border-bottom: 1px solid ${Theme.Colors.grey.s10};
    }
  }
`

const CtaWrap = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${Theme.Mediaquery.medium.max} {
    display: none;
  }
`

const MobileOnly = styled.nav`
  display: block;

  ${Theme.Mediaquery.medium.min} {
    display: none;
  }
`

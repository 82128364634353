import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"
import { CloudinaryContext } from "cloudinary-react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import FbIcon from "../images/icons/facebook.svg"
import IgIcon from "../images/icons/instagram.svg"
import popupImage from "../images/popup-image.jpg"
import Theme from "../lib/Theme"
import { rgba } from "../lib/utils"
import "../normalize.css"
import { Heading } from "./Heading"
import { MailchimpForm } from "./MailchimpForm"
import { Navigation } from "./Navigation"
import { Popup } from "./Popup"
import { Row } from "./Row"
import { SocialIcon } from "./SocialIcon"

export const Layout = ({ children }) => {
  const [showPopup, setPopup] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      }

      kirby {
        site {
          title
          mainNav {
            title
            id
          }
          footerNav {
            title
            id
          }
          legalNav {
            title
            id
          }
        }

        allPackage {
          id
          title
        }
      }
    }
  `)

  const toLink = (item) => ({
    label: item.title,
    url: `/${item.id}`,
  })

  const mainNavPages = data.kirby.site.mainNav.map(toLink)
  const footerNavPages = data.kirby.site.footerNav.map(toLink)
  const legalNavPages = data.kirby.site.legalNav.map(toLink)

  const closePopup = () => {
    // set cookie
    Cookies.set("coronaNotification", "checked", {
      expires: 365,
      sameSite: "Lax",
    })
    setPopup(false)
  }

  useEffect(() => {
    // read corona cookie, if not set, show popup
    // const hasCookie = Cookies.get("coronaNotification")
    // if (!hasCookie) {
    //   setPopup(true)
    // }
  }, [])

  return (
    <CloudinaryContext cloudName="schriktraining-deluxe" secure>
      <Wrap>
        <Global styles={globalCss} />
        <Navigation links={mainNavPages} />

        <Main>{children}</Main>

        {showPopup && (
          <Popup imageUrl={popupImage} onClose={closePopup}>
            <Heading as="h2">Aanpassingen ivm Corona</Heading>
            <p>
              Leuk dat je er bent! Op dit moment kunnen we alleen trainingen
              geven in de buitenlucht in groepjes van maximaal 4 personen per
              les. Alleen jongeren onder de 27 kunnen de les in groepen van 6
              volgen. Ook houden wij rekening met de avondklok en geven alleen
              overdag trainingen.
            </p>
            <p>
              Wil je liever 's avonds of in de binnenbak een training
              organiseren? Of verwacht je meer dan 4 deelnemers per uur? Laat
              dan hieronder je e-mailadres achter. We laten weten wanneer alles
              weer kan.
            </p>

            <MailchimpForm onSubmit={closePopup} />
          </Popup>
        )}

        <Footer>
          <FooterContent>
            <NewsletterColumn>
              <Heading diap size={16} as="h5">
                Nieuwsbrief
              </Heading>

              <p style={{ marginTop: 0 }}>
                Op de hoogte blijven van de nieuwste tips en aanbiedingen? Laat
                dan hieronder je e-mailadres achter.
              </p>

              <MailchimpForm small />
            </NewsletterColumn>

            <div>
              <Heading diap size={16} as="h5">
                Paginas
              </Heading>
              {footerNavPages.map((item) => (
                <Link key={item.url} to={`${item.url}/`}>
                  {item.label}
                </Link>
              ))}
            </div>

            <div>
              <Heading diap size={16} as="h5">
                Pakketten
              </Heading>

              {data.kirby.allPackage.map((item) => (
                <Link key={item.id} to={`/${item.id}/`}>
                  {item.title}
                </Link>
              ))}
            </div>

            <ContactColumn>
              <Heading diap size={16} as="h5">
                Contact
              </Heading>

              <p>
                <span>E-mail</span>
                <a
                  className="link"
                  href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#115;&#99;&#104;&#114;&#105;&#107;&#116;&#114;&#97;&#105;&#110;&#105;&#110;&#103;&#100;&#101;&#108;&#117;&#120;&#101;&#46;&#110;&#108;"
                >
                  &#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#115;&#99;&#104;&#114;&#105;&#107;&#116;&#114;&#97;&#105;&#110;&#105;&#110;&#103;&#100;&#101;&#108;&#117;&#120;&#101;&#46;&#110;&#108;
                </a>
              </p>

              <IconWrap>
                <SocialIcon
                  inverted
                  href="https://www.facebook.com/schriktrainingdeluxe"
                  target="_blank"
                  rel="noreferrer nofollow noopener"
                  title="Facebook"
                  color={Theme.Colors.purple.s75}
                >
                  <FbIcon />
                  <FbIcon />
                </SocialIcon>
                <SocialIcon
                  inverted
                  href="https://www.instagram.com/schriktrainingdeluxe"
                  target="_blank"
                  rel="noreferrer nofollow noopener"
                  title="Instagram"
                  color={Theme.Colors.purple.s75}
                >
                  <IgIcon />
                  <IgIcon />
                </SocialIcon>
              </IconWrap>
            </ContactColumn>
          </FooterContent>

          <LegalRow>
            © {new Date().getFullYear()} Schriktraining Deluxe - KvK 80030572
            {legalNavPages.map((item) => (
              <span key={item.url}>
                {" - "}
                <Link to={`${item.url}/`}>{item.label}</Link>
              </span>
            ))}
          </LegalRow>
        </Footer>
      </Wrap>
    </CloudinaryContext>
  )
}

const globalCss = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    font-family: ${Theme.Font.body.family}, sans-serif;
    line-height: 1.5;
    color: ${Theme.Colors.grey.s75};
  }
`

const Wrap = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1;
  margin-top: 3rem;

  ${Theme.Mediaquery.medium.min} {
    margin-top: 5rem;
  }
`

const Footer = styled.footer`
  position: relative;
  background: ${Theme.Colors.purple.s100};
  border-top: 0.5rem solid ${Theme.Colors.purple.s75};
  padding: 3rem 0 0 0;
  margin-top: 4rem;
  color: #fff;
  font-size: 0.9rem;

  h5 {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      color: ${Theme.Colors.pink.s100};
    }
  }

  div > a {
    display: block;
    margin-bottom: 0.25rem;
  }
`

const FooterContent = styled(Row)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${Theme.Mediaquery.medium.min} {
    gap: 4rem 2rem;
    grid-template-columns: repeat(6, 1fr);
  }
`

const NewsletterColumn = styled.div`
  ${Theme.Mediaquery.medium.min} {
    grid-column: span 2;
  }

  p {
    max-width: 30rem;
  }
`

const ContactColumn = styled.div`
  ${Theme.Mediaquery.medium.min} {
    grid-column: span 2;
  }

  span {
    display: inline-block;
    min-width: 4rem;
    margin-right: 0.5em;
    opacity: 0.75;
  }

  .link {
    display: inline;
    margin: 0;
  }

  p {
    margin: 0 0 0.25rem 0;
  }
`

const IconWrap = styled.p`
  padding-top: 1rem;

  ${SocialIcon} {
    margin-left: 0;
  }

  ${SocialIcon} + ${SocialIcon} {
    margin-left: 0.5rem;
  }
`

const LegalRow = styled.div`
  background: ${rgba(Theme.Colors.purple.s150, 0.25)};
  margin-top: 2rem;
  padding: 2rem;
  text-align: center;
  font-size: 0.8rem;
  opacity: 0.8;

  a {
    display: inline;
    margin: 0;
  }
`
